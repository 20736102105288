import { Ref } from 'vue';

export enum FilterType {
  Search = 'search',
  Selection = 'selection',
  DateUpper = 'date-upper',
  DateLower = 'date-lower',
  Settings = 'settings',
}

export enum FilterMode {
  IN = 'IN',
  CONTAINS = 'CONTAINS',
  NOT_EQUALS = 'NOT_EQUALS',
  EQUALS = 'EQUALS',
  NOT_CONTAINS = 'NOT_CONTAINS',
  DATE_IS = 'DATE_IS',
  IS = 'IS',
  DATE_IS_JSON = 'DATE_IS_JSON',
  DATEONLY = 'DATEONLY',
}

export enum FilterGraphqlMode {
  IN = 'in',
  CONTAINS = 'iLike',
  LIKE = 'like',
  NOT_EQUALS = 'neq',
  EQUALS = 'eq',
  NOT_CONTAINS = 'notIn',
  DATE_IS = 'between',
  IS = 'is',
  // eslint-disable-next-line
  DATE_IS_JSON = 'between',
  GREATER_THAN_OR_EQUAL = 'gte',
  LOWER_THAN_OR_EQUAL = 'lte',
}

export interface FilterActivatorProps {
  header?: string
  field: string
  placeholder?: string
  options?: unknown[]
  type?: FilterType
  mode: FilterMode
  events?: Record<string, (data: unknown) => void>
  replaceSpaces?: string;
  customSearch?: boolean;
  graphqlFilter?: GraphQLFilter;
}

export interface FilterDateModel {
  upper?: Date;
  lower?: Date;
}

export type FilterValue = boolean | string | number | { upper?: string | number | Date; lower?: string | number | Date } | boolean[] | string[] | number[] | undefined;

export interface FilterModel<T = FilterValue> {
  mode?: FilterMode;
  value?: T;
  replaceSpaces?: string;
  graphqlFilter?: GraphQLFilter;
}

export interface GraphQLFilter {
  [key: string]: FilterValue | GraphQLFilter | (FilterValue | GraphQLFilter)[];
}

type DefaultFiltersType = GraphQLFilter | (GraphQLFilter | undefined)[] | undefined;
export interface UseFiltersProvide {
  injected?: unknown;
  graphQlFilter?: boolean;
  providerFilter?: boolean;
  defaultFilters?: DefaultFiltersType | Ref<DefaultFiltersType>;
  filters: Ref<unknown>;
  zIndex?: number;
  someFilterSelected?: Ref<boolean>;
  customFilters?: boolean;
  filterModel: Record<string, FilterModel>;
  enableCache?: boolean;
  onReady?: () => void;
  onDestroy?: () => void;
}

export type FilterEmits = (e: 'update:modelValue', filters: unknown) => null;

export const defaultLowerDate = '1900-01-01T22:00:00.000Z';
export const defaultUpperDate = '2100-01-01T22:00:00.000Z';

import { License, Provider, User } from '@/graphql';

export interface IProviderResolverOptions {
  user: User;
  provider: Provider;
}

export interface ILicenseResolverOptions extends IProviderResolverOptions {
  license: License;
}

type Tags = (string | string[])[];
export type TagResolvers<T> = Record<string, (opts: T & { args: string[] }) => boolean>;

const evaluate = <T>(tags: Tags, opts: T, tagResolvers: TagResolvers<T>) => {
  for (const tag of tags) {
    const arr = Array.isArray(tag) ? tag : [tag];

    // eslint-disable-next-line unicorn/no-array-reduce
    const groupResult = arr.reduce((acc, t) => {
      const split = t.split(':');

      const tag = split[0];
      const args = split.length > 1 ? split[1].split(',') : [];

      const resolverKey = Object.keys(tagResolvers).find(r => r === tag);

      if (!resolverKey) {
        // eslint-disable-next-line no-console
        console.warn(`Tag [${t}] is not implemented`);

        return false;
      }

      return acc && tagResolvers[resolverKey]({
        ...opts,
        args,
      });
    }, true as boolean);

    if (groupResult) {
      return true;
    }
  }

  return false;
};

const commonResolvers: TagResolvers<unknown> = {
  '*': () => true,
};

const providerResolvers: TagResolvers<IProviderResolverOptions> = {
  assigned: ({ user: u, provider: p }) => p && p.assignees && p.assignees.some(a => a.id === u.id),
  org: ({ user: u, provider: p }) => p && p.organizations && p.organizations.some(o => o.id === u.org_id),
  b2b: ({ user: u, provider: p }) => p && p.organizations && p.organizations.length > 0 && p.organizations.every(o => o.id !== u.org_id),
  b2c: ({ provider: p }) => p && p.organizations && p.organizations.length === 0,
};

const licenseResolvers: TagResolvers<ILicenseResolverOptions> = {
  type: ({ license, args }) => license.type.toLowerCase() === args[0],
};

const evaluateProviderTags = (tags: Tags, opts: IProviderResolverOptions) => evaluate(tags, opts, {
  ...commonResolvers,
  ...providerResolvers,
});

const evaluateLicenseTags = (tags: Tags, opts: ILicenseResolverOptions) => evaluate(tags, opts, {
  ...commonResolvers,
  ...licenseResolvers,
  ...providerResolvers,
});

export const useTagEval = () => ({
  evaluate,
  evaluateLicenseTags,
  evaluateProviderTags,
  resolvers: {
    common: commonResolvers,
    provider: providerResolvers,
    license: licenseResolvers,
  },
});

import { unref } from 'vue';
import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router';
import { useCookies } from 'vue3-cookies';

import { errorHandler } from '@/modules/feedback';

export const createAppRouter = (routes: RouteRecordRaw[]) => {
  const router = createRouter({
    routes,
    history: createWebHistory(),
  });

  const { cookies } = useCookies();

  router.afterEach((to, from) => {
    const global = useGlobalsStore();
    if (to.path !== from.path) document?.querySelector('main')?.scrollTo(0, 0);

    global.routerLoading = false;
  });

  router.beforeEach((to, from, next) => {
    const store = useAuthStore();
    const global = useGlobalsStore();
    const auth = store.isAuthorized;
    const forGuest = !!to.meta?.guest;

    global.routerLoading = true;

    if (localStorage.versionFromApi && localStorage.versionFromApi !== localStorage.credsyVersion) {
      localStorage.credsyVersion = localStorage.versionFromApi as string;
      window.location.href = to.fullPath;
      return true;
    }

    if (to.name === 'sharedprofile-check-email' && cookies.get('sharePdfToken')) {
      return next(to.query?.after as string);
    }

    if (to.name === 'sharedprofile' && !cookies.get('sharePdfToken')) {
      return next(`/sharedprofile-check-email?after=${to.fullPath}`);
    }

    // page guest policy should not be equal to auth state
    if (auth !== forGuest) {
      // if (to.path === '/' && store.isProvider) {
      //   return next('/profile');
      // }

      if (to.meta?.visible) {
        const value = unref(to.meta?.visible);

        if (!value) {
          return next('/restricted');
        }
      }

      return next();
    }

    // handle guest pages
    if (auth && forGuest) {
      return next('/');
    }

    // redirect guests to login screen
    return next(`/auth/login?after=${to.fullPath}`);
  });

  router.onError(errorHandler);

  return router;
};

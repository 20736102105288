import { Action, CoreUser, isCompanyAdmin, isProvider, isSuperadmin, Resource } from '@ml/shared';
import { UserStatus } from '@ml/shared/enums';
import { flatten } from 'lodash-es';
import type { Ref } from 'vue';

import { MeQuery, Provider, Role, Session, User } from '@/graphql';

export type ResolvedUser = MeQuery['me']['user'];

interface AuthStateType {
  user: ResolvedUser | null;
  session: MeQuery['me']['session'],
  avatars: { path: string; }[],
  trial: string | null | undefined,
}

export const useAuthStore = defineStore({
  id: 'auth',
  state: (): AuthStateType => ({
    user: null,
    session: null,
    avatars: [],
    trial: '',
  }),

  actions: {
    setAvatar(path: string) {
      if (this.avatars.length === 0) {
        this.avatars.push({
          path,
        });
      }

      this.avatars[0].path = path;
    },
    setTimeZone(timezone: string) {
      const user = {
        ...this.user,
      };
      user.timezone = timezone;
      // @ts-ignore: ??
      this.user = user;
    },
  },

  getters: {
    isAuthorized: state => !!state.user,
    isRootOrg: state => !!state.user?.organization?.is_root,
    can: state => (action: string) => {
      if (!state.user) {
        return false;
      }

      const permissions = flatten(state.user?.roles?.map(r => r.permissions as string[]));

      return permissions.includes('superuser') || permissions.includes(action);
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    scopes(state): (ares: string) => (string | string[])[] {
      const merged = this.mergedPermissions;

      return (ares: string) => {
        if (!merged[ares]) {
          return [];
        }

        return merged[ares];
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAnyScope(state): (ares: string) => boolean {
      const admin = this.isAdmin;
      const scopes = this.scopes;

      return (ares: string) => {
        if (admin) {
          return true;
        }

        // @ts-ignore: ??
        return scopes(ares) > 0;
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasPageAccess(state): (page: string) => boolean {
      const admin = this.isAdmin;
      const provider = this.isProvider;
      const merged = this.mergedPermissions;

      return (page: string): boolean => {
        if (admin) {
          return true;
        }

        if (provider && ['licenses', 'documents', 'credentials', 'cme-requirements', 'cme-credits'].includes(page)) {
          return true;
        }

        return 'read.page' in merged && merged['read.page'].includes(page);
      };
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasAssignAccess(state): boolean {
      if (this.isAdmin) {
        return true;
      }

      const merged = this.mergedPermissions;

      return 'assign.provider' in merged && merged['assign.provider'].length > 0;
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    hasProviderAccess(state): (ares: string | { a: Action, res: Resource }, p?: Partial<Provider> | Ref<Partial<Provider>>) => boolean {
      const { evaluateProviderTags } = useTagEval();

      const user = this.user;
      const superadmin = this.isSuperadmin;
      const merged = this.mergedPermissions;
      const companyAdmin = this.isCompanyAdmin;

      return (ares: string | { a: Action, res: Resource }, p?: Partial<Provider> | Ref<Partial<Provider>>): boolean => {
        const provider = unref(p);

        if (superadmin) {
          return true;
        }

        if (user?.provider?.id === provider?.id) {
          return true;
        }

        if (companyAdmin && provider?.organizations?.some(o => o.id === user?.org_id)) {
          return true;
        }

        const mergedAres = typeof ares === 'string' ? ares : `${ares.a}.${ares.res}`;

        const allowedTags = merged[mergedAres];

        if (!allowedTags || !provider || !user) {
          return false;
        }

        return evaluateProviderTags(allowedTags, {
          provider: provider as Provider, user: user as User,
        });
      };
    },
    canBeAssigned: state => state.user?.roles?.some(r => r.provider_assign),
    mergedPermissions: state => {
      // @ts-ignore: ??
      const roles: Role[] = state.user?.roles ?? [];

      const merged: Record<string, (string | string[])[]> = {};

      for (const role of roles) {
        for (const [ares, tags] of Object.entries(role.permissions)) {
          if (!merged[ares as string]) {
            merged[ares as string] = [];
          }

          merged[ares as string].push(...tags as string[]);
        }
      }

      return merged;
    },
    avatar: state => {
      if (state.avatars[0]) {
        return state.avatars[0];
      }

      if (state.user?.avatars && state.user.avatars.length > 0 && state.user.avatars[0]) {
        return state.user.avatars[0];
      }

      return '';
    },
    isProvider() {
      // @ts-ignore: ??
      return isProvider(this.user);
    },
    isUser() {
      // @ts-ignore: ??
      return isProvider(this.user);
    },
    isSuperUser() {
      // @ts-ignore: ??
      return !isProvider(this.user);
    },
    isSuperadmin: state => Boolean(state.user?.roles && isSuperadmin(state.user as CoreUser)),
    isCompanyAdmin: state => state.user?.roles && isCompanyAdmin(state.user as CoreUser),
    isAdmin(): boolean {
      return Boolean(this.isSuperadmin || this.isCompanyAdmin);
    },
    lastSession(): Session | object {
      if (!this.user?.sessions || (this.user?.sessions?.nodes && this.user.sessions.nodes.length === 0)) {
        return {};
      }

      return this.user?.sessions.nodes[0];
    },
    isHold(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Hold;
    },
    isTrial(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Trial;
    },
    isExpired(): boolean {
      if (!isProvider(this.user as CoreUser) || !this.user?.status) {
        return false;
      }

      return UserStatus[this.user?.status] === UserStatus.Expired;
    },
  },
});
